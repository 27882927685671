import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { baseUrl } from '../../auth/api'
import { startLoader, stopLoader } from './LoaderSlice'

const initialState = {
    allQrCodes: [],
    loading: true,
    error: false
}
export const getAllQrCodeThunk = createAsyncThunk("getAllQrcodes", async (data, api) => {
    api.dispatch(startLoader())
    let res = await axios.get(baseUrl + "qrCode/getAllQrcodeDatadetails")
    api.dispatch(stopLoader())
    return res.data.reverse()
})
export const AllQrCodeSlice = createSlice({
    name: 'allQrCode',
    initialState,
    reducers: {
        clearAllQrData: () => {
            return ({
                allQrCodes: [],
                loading: true,
                error: false
            })
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllQrCodeThunk.fulfilled, (state, action) => {
            return ({ loading: false, allQrCodes: action.payload, error: false })
        })
        builder.addCase(getAllQrCodeThunk.pending, (state, action) => {
            return ({ ...state, loading: true })
        })
        builder.addCase(getAllQrCodeThunk.rejected, (state, action) => {
            return ({ ...state, loading: false, error: true })
        })
    }
})

export const {clearAllQrData} =AllQrCodeSlice.actions
